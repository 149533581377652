import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { styled } from '@mui/material/styles';
import { ONE_HOUR_IN_SECONDS, t } from '../constants';
import { isMobile } from '../utils';

const CustomPopup = styled(Popup)`
  text-align: center;
`;

const LastStationSpan = styled('span')`
  color: red;
  font-weight: bold;
`;

const VehicleMarker = ({
  vehicle: { vehicleLat, vehicleLong, vehicleName, vehicleDate, vehicleSpeed },
  lastStationName,
  icon,
  openPopup,
}) => {
  const markerRef = useRef();
  const [_, setHoverVehicle] = useState(false); //eslint-disable-line no-unused-vars

  useEffect(() => {
    if (markerRef.current && openPopup) {
      markerRef.current.openPopup();
    }
  }, [openPopup]);

  const secondsNo = vehicleDate
    ? Math.abs(
        Math.round(
          (new Date() - new Date(vehicleDate.replace(/\s/, 'T'))) / 1000,
        ),
      )
    : NaN;

  let eventHandlers = !isMobile()
    ? {
        mouseover: (event) => {
          setHoverVehicle(true);
          event.target.openPopup();
        },
        mouseout: (event) => {
          event.target.closePopup();
          setTimeout(() => setHoverVehicle(false), 200);
        },
      }
    : {};

  if (!vehicleLat || !vehicleLong) {
    return '';
  }

  return (
    <Marker
      position={[vehicleLat, vehicleLong]}
      icon={icon}
      eventHandlers={eventHandlers}
      opacity={secondsNo <= ONE_HOUR_IN_SECONDS ? 1 : 0.6}
      ref={markerRef}
    >
      <CustomPopup autoPan={false} closeButton={false}>
        <p>
          {t('parkNo')} {vehicleName}
        </p>

        {secondsNo <= ONE_HOUR_IN_SECONDS ? ( // 1 hour
          <Fragment>
            <p>{t('updated', { secondsNo })}</p>

            <p>{t('speed', { vehicleSpeed: vehicleSpeed })}</p>

            <p>
              {t('goingTo')}
              <LastStationSpan style={{ color: 'red', fontWeight: 'bold' }}>
                {lastStationName}
              </LastStationSpan>
            </p>
          </Fragment>
        ) : (
          <p>{t('gpsError')}</p>
        )}
      </CustomPopup>
    </Marker>
  );
};

export default React.memo(
  VehicleMarker,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.vehicle) === JSON.stringify(nextProps.vehicle) &&
    prevProps.lastStationName === nextProps.lastStationName &&
    prevProps.openPopup === nextProps.openPopup,
);
